































































































































































































































































































































































































































































































































import Vue from 'vue';

export default Vue.extend({
  data() {
    // const hitem1 = {
    //   title: "",
    //   content:
    //     "",
    // };
    // const hitem2 = {
    //   title: "",
    //   content:
    //     "",
    // };
    // const hitem3 = {
    //   title: "",
    //   content:
    //     "",
    // };
    // const hitem4 = {
    //   title: "",
    //   content:
    //     "",
    // };
    // const hitem5 = {
    //   title: "",
    //   content:
    //     "",
    // };
    // const hitems = [hitem1, hitem2, hitem3, hitem4, hitem5];



    return {
        // hitems,
        dialog: false,

        interval: {},
        value: 0,

        filteritem: [
          'Grid',
          'List',
        ],

        simpletables: [
          {
            appname: 'Easy Webinar',
            bidvalue: '$20',
            campaigns: '5',
            age: '26-35',
            gender: 'Male,Female',
            device: 'i-pad',
            geo: 'Japan',
            usertype: 'New User',
            keywordfetching: 'USD',
            overallprogress: 'USD',
          },
        ],

        messages: [
          {
            from: `Fetching keywords for Brand`,
          },
          {
            from: 'Fetching keywords for Discovery',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
          {
            from: 'Fetching keywords for Competitor',
          },
          {
            from: 'Fetching keywords for Custom campaign',
          },
        ],


      e1: 1,

      switch1: true,
      switch2: false,
      devices: ['In Progress', 'In progress Campaigns', 'Complete Campaigns', 'All Campaigs'],

      endTimeCheck: false,

      selectedApp: null,

      valid: true,
      valid1: false,
      valid2: false,
      valid3: false,
      name: '',
      email: '',
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],

    };
  },



  beforeDestroy () {
    //clearInterval(this.interval)
  },
  mounted () {
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0)
      }
      this.value += 10
    }, 1000)
  },

});
