var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard dashboard-with-banner" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container" },
          [
            _c(
              "v-container",
              { staticClass: "stepper-template", attrs: { fluid: "" } },
              [
                [
                  _c("div", { staticClass: "campaign-list-header" }, [
                    _c(
                      "div",
                      { staticClass: "campaign-list-header-left" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "list-left-col",
                                attrs: { lg: "6", md: "6", sm: "12" },
                              },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-medium primary--text",
                                  },
                                  [_vm._v("Campaigns")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "view-fields" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "primary darken-1",
                                          outlined: "",
                                          text: "",
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-view-grid")])],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          color: "grey darken-1",
                                          outlined: "",
                                          text: "",
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-view-list")])],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "v-col",
                              {
                                staticClass: "list-right-col",
                                attrs: { lg: "6", md: "6", sm: "12" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Regular",
                                        clearable: "",
                                        "append-icon": "mdi-magnify ",
                                      },
                                      model: {
                                        value: _vm.message1,
                                        callback: function ($$v) {
                                          _vm.message1 = $$v
                                        },
                                        expression: "message1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "field" },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        items: _vm.devices,
                                        label: "In Progress",
                                        required: "",
                                        outlined: "",
                                        dense: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "v-card",
                    { staticClass: "mb-12 pa-11", attrs: { elevation: "4" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { lg: "4", md: "6", sm: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "campaignLists card mt-0 pa-6",
                                  attrs: { width: "100%" },
                                },
                                [
                                  _c("v-card-text", { staticClass: "pa-0" }, [
                                    _c(
                                      "div",
                                      { staticClass: "list-header-col" },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5 pa-0" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h4 text--primary",
                                              },
                                              [_vm._v("Easy Webinar")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-1 pa-0" },
                                          [_vm._v("CPT Bid : $40.00")]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-2 pa-0" },
                                          [
                                            _vm._v("Campaign: "),
                                            _c("span", [_vm._v("5")]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "list-header-right" },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c("v-list-item", [
                                              _vm._v("Gender: "),
                                              _c("span", [
                                                _vm._v("Male,Female"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Age: "),
                                              _c("span", [_vm._v("25-35")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Device: "),
                                              _c("span", [
                                                _vm._v("i-phone, i-pad"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Geo/Region: "),
                                              _c("span", [_vm._v("Japan")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("User Type: "),
                                              _c("span", [_vm._v("New User")]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-timeline",
                                        {
                                          attrs: { "align-top": "", dense: "" },
                                        },
                                        [
                                          _vm._l(
                                            _vm.messages,
                                            function (message) {
                                              return _c(
                                                "v-timeline-item",
                                                {
                                                  key: message.time,
                                                  attrs: {
                                                    color: "",
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "timeline-text font-weight-normal",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              message.from
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-timeline-item",
                                            { attrs: { color: "", small: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "grey darken-1",
                                                    text: "",
                                                  },
                                                },
                                                [_vm._v(" + 5 More ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "progress-bar" },
                                        [
                                          _c(
                                            "v-progress-circular",
                                            {
                                              attrs: {
                                                rotate: 360,
                                                size: 55,
                                                width: 6,
                                                value: _vm.value,
                                                color: "teal",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.value) + "% "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { lg: "4", md: "6", sm: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "campaignLists card mt-0 pa-6",
                                  attrs: { width: "100%" },
                                },
                                [
                                  _c("v-card-text", { staticClass: "pa-0" }, [
                                    _c(
                                      "div",
                                      { staticClass: "list-header-col" },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5 pa-0" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h4 text--primary",
                                              },
                                              [_vm._v("Easy Webinar")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-1 pa-0" },
                                          [_vm._v("CPT Bid : $40.00")]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-2 pa-0" },
                                          [
                                            _vm._v("Campaign: "),
                                            _c("span", [_vm._v("5")]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "list-header-right" },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c("v-list-item", [
                                              _vm._v("Gender: "),
                                              _c("span", [
                                                _vm._v("Male,Female"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Age: "),
                                              _c("span", [_vm._v("25-35")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Device: "),
                                              _c("span", [
                                                _vm._v("i-phone, i-pad"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Geo/Region: "),
                                              _c("span", [_vm._v("Japan")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("User Type: "),
                                              _c("span", [_vm._v("New User")]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-timeline",
                                        {
                                          attrs: { "align-top": "", dense: "" },
                                        },
                                        [
                                          _vm._l(
                                            _vm.messages,
                                            function (message) {
                                              return _c(
                                                "v-timeline-item",
                                                {
                                                  key: message.time,
                                                  attrs: {
                                                    color: "",
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "timeline-text font-weight-normal",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              message.from
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-timeline-item",
                                            { attrs: { color: "", small: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "grey darken-1",
                                                    text: "",
                                                  },
                                                },
                                                [_vm._v(" + 5 More ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "progress-bar" },
                                        [
                                          _c(
                                            "v-progress-circular",
                                            {
                                              attrs: {
                                                rotate: 360,
                                                size: 55,
                                                width: 6,
                                                value: _vm.value,
                                                color: "teal",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.value) + "% "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { lg: "4", md: "6", sm: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "campaignLists card mt-0 pa-6",
                                  attrs: { width: "100%" },
                                },
                                [
                                  _c("v-card-text", { staticClass: "pa-0" }, [
                                    _c(
                                      "div",
                                      { staticClass: "list-header-col" },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5 pa-0" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h4 text--primary",
                                              },
                                              [_vm._v("Easy Webinar")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-1 pa-0" },
                                          [_vm._v("CPT Bid : $40.00")]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-2 pa-0" },
                                          [
                                            _vm._v("Campaign: "),
                                            _c("span", [_vm._v("5")]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "list-header-right" },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c("v-list-item", [
                                              _vm._v("Gender: "),
                                              _c("span", [
                                                _vm._v("Male,Female"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Age: "),
                                              _c("span", [_vm._v("25-35")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Device: "),
                                              _c("span", [
                                                _vm._v("i-phone, i-pad"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Geo/Region: "),
                                              _c("span", [_vm._v("Japan")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("User Type: "),
                                              _c("span", [_vm._v("New User")]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-timeline",
                                        {
                                          attrs: { "align-top": "", dense: "" },
                                        },
                                        [
                                          _vm._l(
                                            _vm.messages,
                                            function (message) {
                                              return _c(
                                                "v-timeline-item",
                                                {
                                                  key: message.time,
                                                  attrs: {
                                                    color: "",
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "timeline-text font-weight-normal",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              message.from
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-timeline-item",
                                            { attrs: { color: "", small: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "grey darken-1",
                                                    text: "",
                                                  },
                                                },
                                                [_vm._v(" + 5 More ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "progress-bar" },
                                        [
                                          _c(
                                            "v-progress-circular",
                                            {
                                              attrs: {
                                                rotate: 360,
                                                size: 55,
                                                width: 6,
                                                value: _vm.value,
                                                color: "yellow",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.value) + "% "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { lg: "4", md: "6", sm: "12" } },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "campaignLists card mt-0 pa-6",
                                  attrs: { width: "100%" },
                                },
                                [
                                  _c("v-card-text", { staticClass: "pa-0" }, [
                                    _c(
                                      "div",
                                      { staticClass: "list-header-col" },
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5 pa-0" },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-h4 text--primary",
                                              },
                                              [_vm._v("Easy Webinar")]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-1 pa-0" },
                                          [_vm._v("CPT Bid : $40.00")]
                                        ),
                                        _c(
                                          "v-card-subtitle",
                                          { staticClass: "title-2 pa-0" },
                                          [
                                            _vm._v("Campaign: "),
                                            _c("span", [_vm._v("5")]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "list-header-right" },
                                      [
                                        _c(
                                          "v-list",
                                          [
                                            _c("v-list-item", [
                                              _vm._v("Gender: "),
                                              _c("span", [
                                                _vm._v("Male,Female"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Age: "),
                                              _c("span", [_vm._v("25-35")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Device: "),
                                              _c("span", [
                                                _vm._v("i-phone, i-pad"),
                                              ]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("Geo/Region: "),
                                              _c("span", [_vm._v("Japan")]),
                                            ]),
                                            _c("v-list-item", [
                                              _vm._v("User Type: "),
                                              _c("span", [_vm._v("New User")]),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("v-divider", { staticClass: "mb-4" }),
                                  _c(
                                    "v-card-text",
                                    { staticClass: "pa-0" },
                                    [
                                      _c(
                                        "v-timeline",
                                        {
                                          attrs: { "align-top": "", dense: "" },
                                        },
                                        [
                                          _vm._l(
                                            _vm.messages,
                                            function (message) {
                                              return _c(
                                                "v-timeline-item",
                                                {
                                                  key: message.time,
                                                  attrs: {
                                                    color: "",
                                                    small: "",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "timeline-text font-weight-normal",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              message.from
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "v-timeline-item",
                                            { attrs: { color: "", small: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "grey darken-1",
                                                    text: "",
                                                  },
                                                },
                                                [_vm._v(" + 5 More ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "progress-bar" },
                                        [
                                          _c(
                                            "v-progress-circular",
                                            {
                                              attrs: {
                                                rotate: 360,
                                                size: 55,
                                                width: 6,
                                                value: _vm.value,
                                                color: "teal",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.value) + "% "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "campaign-list-view" },
                        [
                          _c("v-simple-table", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" App Name ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" CPT Bid Value ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" Campaigns ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" Age ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" Gender ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" Device ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" Geo ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" User Type ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" Keywords Fetching ")]
                                        ),
                                        _c(
                                          "th",
                                          {
                                            staticClass: "text-left",
                                            attrs: { scope: "col" },
                                          },
                                          [_vm._v(" Overall Progress ")]
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      [
                                        _vm._l(
                                          _vm.simpletables,
                                          function (item) {
                                            return _c(
                                              "tr",
                                              { key: item.action },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(item.appname)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.bidvalue)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.campaigns)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.age)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.gender)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.device)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.geo)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.usertype)),
                                                ]),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-timeline",
                                                      {
                                                        staticClass:
                                                          "horizontal-timeline",
                                                        attrs: {
                                                          "align-top": "",
                                                          dense: "",
                                                        },
                                                      },
                                                      _vm._l(
                                                        _vm.messages,
                                                        function (message) {
                                                          return _c(
                                                            "v-timeline-item",
                                                            {
                                                              key: message.time,
                                                              attrs: {
                                                                color: "",
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "timeline-text font-weight-normal",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          message.from
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "progress-bar",
                                                    },
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            rotate: 360,
                                                            size: 55,
                                                            width: 6,
                                                            value: _vm.value,
                                                            color: "teal",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.value
                                                              ) +
                                                              "% "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._l(
                                          _vm.simpletables,
                                          function (item) {
                                            return _c(
                                              "tr",
                                              { key: item.action },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(item.appname)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.bidvalue)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(item.campaigns)
                                                  ),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.age)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.gender)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.device)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.geo)),
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(item.usertype)),
                                                ]),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function (
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                var attrs =
                                                                  ref.attrs
                                                                return [
                                                                  _c(
                                                                    "v-timeline",
                                                                    _vm._g(
                                                                      _vm._b(
                                                                        {
                                                                          staticClass:
                                                                            "horizontal-timeline",
                                                                          attrs: {
                                                                            "align-top":
                                                                              "",
                                                                            dense:
                                                                              "",
                                                                          },
                                                                        },
                                                                        "v-timeline",
                                                                        attrs,
                                                                        false
                                                                      ),
                                                                      on
                                                                    ),
                                                                    _vm._l(
                                                                      _vm.messages,
                                                                      function (
                                                                        message
                                                                      ) {
                                                                        return _c(
                                                                          "v-timeline-item",
                                                                          {
                                                                            key:
                                                                              message.time,
                                                                            attrs: {
                                                                              color:
                                                                                "",
                                                                              small:
                                                                                "",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "timeline-text font-weight-normal",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          message.from
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Top tooltip"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "progress-bar",
                                                    },
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            rotate: 360,
                                                            size: 55,
                                                            width: 6,
                                                            value: _vm.value,
                                                            color: "teal",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.value
                                                              ) +
                                                              "% "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }